@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Agbalumo&family=Rubik:wght@300;400;500;700;900&display=swap");

$main-color: #dd2f6e;
$dark-color: #1d2231;
$text-gray: #eee;
$text-white: #ffffff;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: Rubik;
}

.app {
  height: 100vh;
  width: 100vw;
}

.app-header {
  height: 50px;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.app-header h1 {
  font-size: 20px;
  margin: 0;
}

.app-header h1 span {
  font-family: Agbalumo !important;
  font-size: 28px;
  margin: 0;
}

.app-header-nav {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 2;
  gap: 10px;
}

.app-header-nav a {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  text-decoration: none;
}

.app-header-nav a:hover {
  background: #8390a2;
}

.app-header-nav a i {
  font-size: 1.5rem;
  color: $dark-color;
}

.app-main {
  height: calc(100% - 50px);
  display: flex;
}

.app-main-nav {
  height: 100%;
  width: 200px;
}

.app-main-content {
  background: #eee;
  flex: 1;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
}

@import "app.sidebar";
@import "app.form";
@import "app.flash";
@import "app.grid";
@import "app.product.detail";
@import "app.shop.detail";
