.app-shop-details {
  display: flex;
  align-items: start;
  height: 100vh;
}

.app-shop-details-nav {
  width: 300px;
  background: #eee;
  height: 100%;
}

.app-shop-details-content {
  flex: 1;
  height: 100%;
}

.shop-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  text-decoration: none;
}
.shop-item:hover {
  background: #ccc;
}
.shop-item.selected {
  background: #ccc;
}
.shop-item h5 {
  color: $dark-color;
}
