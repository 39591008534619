.app-sidebar {
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 100%;
  left: 0;
  top: 0;
}

.app-sidebar-menu {
  flex: 1;
}

.app-sidebar-menu ul {
  padding: 0 !important;
}

.app-sidebar-menu li {
  width: 100%;
}

.app-sidebar-menu a {
  display: block;
  color: black;
  font-size: 0.9rem;
  text-decoration: none;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.app-sidebar-menu a.active,
.app-sidebar-menu a:hover {
  background: $text-gray;
}

.app-sidebar-menu a i {
  font-size: 1.2rem;
  padding-right: 1rem;
}
